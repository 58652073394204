import styles from "./LeadTypeButton.module.scss";
import { useRef } from "react";

export default function LeadTypeButton(props) {
  const { children, className, innerClassName, isRow, ...childrenProps } =
    props;

  const inner = useRef();

  return (
    <button
      className={styles["LeadTypeButton"] + " " + className}
      {...childrenProps}
    >
      <span>
        <span>
          <span
            ref={inner}
            className={styles["LeadTypeButton__inner"] + " " + innerClassName}
          >
            {children}
          </span>
        </span>
      </span>
    </button>
  );
}
