import { useState, useEffect, useCallback } from "react";
import { useSpring, animated as a } from "@react-spring/web";
import styles from "./styles/ViewAgentsUserPrompt.module.sass";
import { useTranslation } from "react-i18next";
import { getCompletedLeads, getContact } from "../../config/Lead";
import { createFunnelEvent } from "../../config/Analytics";
import { ASSET_BASE_URL } from "../../config/constants";
import { useExperiment } from "/contexts/ExperimentContext";
import Link from "next/link";

const isMobile =
  process.browser &&
  navigator.userAgent.match(
    /(iPhone|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i
  );

const userAgent = process.browser && navigator.userAgent.toLowerCase();
const isTablet =
  /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
    userAgent
  );

const ViewAgentsUserPrompt = ({ type }) => {
  const { t } = useTranslation(["translation", "common"]);
  const [showPrompt, displayShowPrompt] = useState(false);
  const [leadToLink, setLeadToLink] = useState("");
  const [name, setName] = useState("");
  const { experiment } = useExperiment();

  const animationProps = useSpring({
    config: {
      duration: 400,
    },
    top: showPrompt ? (isTablet ? 16 : isMobile ? 0 : 16) : -350,
  });

  /**
   * Tracks an event
   */
  const trackEvent = useCallback(
    () =>
      ({ event_type, data }) => {
        let params = {
          eventType: event_type,
          data,
          step: "postconvert",
          leadType: type,
        };
        createFunnelEvent({ ...params, details: data });
      },
    [type]
  );

  useEffect(() => {
    if (showPrompt) {
      trackEvent({ event_type: "return_banner_show" });
    }
  }, [showPrompt, trackEvent]);

  useEffect(() => {
    // const pollForMatches = async () => {
    //   if (!pollMatchesRan.current) {
    //     pollMatchesRan.current = true;
    //     const matches = await pollMatches();
    //     displayShowPrompt(matches?.resp?.data?.agents?.length);
    //   }
    // };

    // pollForMatches();
    const { total } = getCompletedLeads();
    displayShowPrompt(total > 0);
  }, []);

  useEffect(() => {
    const { leads } = getCompletedLeads();
    const mostRecentLead = leads[0];
    setLeadToLink(mostRecentLead);
    const name = getContact("firstName");
    setName(name);
  }, []);

  const Url = () => {
    return `/app?force_path=true&uuid=${leadToLink}&show_rea_funnel_skin=true`;
  };

  /**
   * Tracks agent view by type
   * @param { String } type -- type is either hyperlink or button
   */
  const trackAgentView = (type) => {
    trackEvent({ event_type: `return_banner_clickthrough_${type}` });
  };

  const renderContent = () => {
    return (
      <div className={styles.row}>
        <div
          className={styles.closeButton}
          onClick={(e) => {
            displayShowPrompt(false);
            trackEvent({ event_type: "return_banner_close" });
            return false;
          }}
        >
          <i className="fal fa-times" />
        </div>
        <div className={styles.logoRow}>
          <img
            className={styles.logo}
            alt="realestateagents wave logo"
            src={`${ASSET_BASE_URL}/logo-only.png`}
          />
          <div className={styles.column}>
            <h2 className={styles.header}>
              {t("welcome_back")}, {name}! {t("click_to")}{" "}
              <a
                href={Url()}
                className={styles.link}
                onClick={() => trackAgentView("link")}
              >
                {t("view_your_agents")}.
              </a>
            </h2>
          </div>
        </div>
        <Link
          href={Url()}
          onClick={() => trackAgentView("button")}
          className={styles.button}
        >
          {t("view_your_agents")}
        </Link>
        <button
          onClick={() => {
            displayShowPrompt(false);
            trackEvent({ event_type: "return_banner_close" });
            return false;
          }}
          className={(styles.button, styles.dismissButton)}
        >
          {t("dismiss")}
        </button>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {showPrompt && (
        <div
          className={styles.overlay}
          onClick={(e) => {
            displayShowPrompt(false);
            trackEvent({ event_type: "return_banner_close" });
            return false;
          }}
        />
      )}
      <a.div style={animationProps} className={styles.promptContainer}>
        {renderContent()}
      </a.div>
    </div>
  );
};

export default ViewAgentsUserPrompt;
