import { useExperiment } from "../../../../contexts/ExperimentContext";
import styles from "./PanelSpacer.module.scss";

export default function PanelSpacer({}) {
  const { experiment } = useExperiment();
  return (
    <div
      className={[styles["PanelSpacer"], styles["PanelSpacerNew"]].join(" ")}
    ></div>
  );
}
