import styles from "/components/experiments/reskin/organisms//Page.module.scss";
import Page from "/components/experiments/reskin/organisms//Page";
import Panel from "/components/experiments/reskin/organisms//Panel";
import PanelHeading from "/components/experiments/reskin/molecules/PanelHeading";
import PanelSpacer from "/components/experiments/reskin/molecules/PanelSpacer";
import LeadTypeButton from "/components/experiments/reskin/molecules/LeadTypeButton";

import { useTranslation } from "react-i18next";
import { isMobile as isMobileDetect } from "react-device-detect";
import ViewAgentsUserPrompt from "../../navigation/ViewAgentsUserPrompt";
import { useRouter } from "next/router";
import { createFunnelRequest } from "../../../config/Analytics";
import { ASSET_BASE_URL } from "../../../config/constants";
import { useExperiment } from "../../../contexts/ExperimentContext";
import SellerIcon from "../../svg/SellerIcon";
import BuyerIcon from "../../svg/BuyerIcon";
import BuyerSellerIcon from "../../svg/BuyerSellerIcon";
import { useEffect, useState } from "react";

export default function Reskin({ agentTaxonomy, chosenLocale, setLeadType }) {
  const { t } = useTranslation(["translation", "common"]);
  const router = useRouter();

  const [isMobile, setIsMobile] = useState(isMobileDetect);

  useEffect(() => {
    setIsMobile(isMobileDetect);
  }, []);

  const { experiment } = useExperiment();

  const leadButtonClick = async (leadType) => {
    const parameters = { leadType };
    await createFunnelRequest({
      page: ASSET_BASE_URL + router.asPath.split("?")[0],
      request_type: "POST",
      parameters,
    });

    setLeadType(leadType);
  };

  const iconSize = isMobile ? 36 : 50;

  return (
    <>
      {<ViewAgentsUserPrompt type={router.query.leadType} />}
      <link
        rel="canonical"
        href={"https://www.realestateagents.com/compare-agents"}
      />
      <Page>
        {isMobile && (
          <>
            <h1 className={[styles["Page__heading"]].join(" ")}>
              <div>
                {t(
                  chosenLocale ? "headline_alternate" : "headline_experiment",
                  {
                    chosenLocale,
                    agent_taxonomy: agentTaxonomy,
                  }
                )}
              </div>{" "}
              {t("in")} {chosenLocale ? chosenLocale : t("your_area")}
            </h1>
            <div className={[styles["Page__sub-heading"]].join(" ")}>
              {t("signup02.rea.subheading")}
            </div>
          </>
        )}
        <Panel
          className={[
            styles["Page__panel"],
            !isMobile && styles["Page__panel_new_landing"],
          ].join(" ")}
          showProgressBar={!isMobile}
          large={false}
        >
          {!isMobile && (
            <>
              <h1
                className={[
                  styles["Page__heading"],
                  styles["Page__heading_new"],
                ].join(" ")}
              >
                <div>
                  {t(`headline_alternate${isMobile ? "_mobile" : ""}`, {
                    chosenLocale,
                    agent_taxonomy: agentTaxonomy,
                  })}
                </div>{" "}
                {chosenLocale ? <br /> : null}
                {chosenLocale ? t("in") + " " + chosenLocale : t("for_you")}
              </h1>
              <div
                className={[
                  styles["Page__sub-heading"],
                  styles["Page__sub-heading-new"],
                ].join(" ")}
              >
                {t(
                  isMobile
                    ? "signup02.rea.subheading_mobile"
                    : "signup02.rea.subheading"
                )}
              </div>
            </>
          )}

          {isMobile && (
            <PanelHeading center={true}>{t("signup02.rea.cta")}</PanelHeading>
          )}
          <div
            className={[
              styles["Page__button-grid"],
              !isMobile && styles["Page__button-grid-bigger"],
            ].join(" ")}
          >
            <LeadTypeButton
              className={!isMobile && styles["LeadTypeButtonNew"]}
              innerClassName={!isMobile && styles["LeadTypeButtonNewInner"]}
              onClick={() => leadButtonClick("buyer")}
            >
              <BuyerIcon width={iconSize} height={iconSize} />
              {t("signup02.rea.im_buying")}
              {!isMobile && (
                <>
                  <i
                    className={`fa-sharp fa-solid fa-circle-chevron-right ${styles["ButtonChevron"]}`}
                  ></i>
                </>
              )}
            </LeadTypeButton>
            <LeadTypeButton
              className={!isMobile && styles["LeadTypeButtonNew"]}
              innerClassName={!isMobile && styles["LeadTypeButtonNewInner"]}
              onClick={() => leadButtonClick("seller")}
            >
              <SellerIcon width={iconSize} height={iconSize} />
              {t("signup02.rea.im_selling")}
              {!isMobile && (
                <>
                  <i
                    className={`fa-sharp fa-solid fa-circle-chevron-right ${styles["ButtonChevron"]}`}
                  ></i>
                </>
              )}
            </LeadTypeButton>
            <LeadTypeButton
              className={!isMobile && styles["LeadTypeButtonNew"]}
              innerClassName={!isMobile && styles["LeadTypeButtonNewInner"]}
              onClick={() => leadButtonClick("both")}
            >
              <BuyerSellerIcon width={iconSize} height={iconSize} />
              {t("signup02.rea.im_buying_and_selling")}
              {!isMobile && (
                <>
                  <i
                    className={`fa-sharp fa-solid fa-circle-chevron-right ${styles["ButtonChevron"]}`}
                  ></i>
                </>
              )}
            </LeadTypeButton>
          </div>
          <PanelSpacer />
          <ul
            className={[
              styles["Page__list"],
              !isMobile && styles["Page__list_bigger"],
            ].join(" ")}
          >
            <li>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("signup02.rea.benfits.benfit_1", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
            </li>
            <li>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("signup02.rea.benfits.benfit_2", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
            </li>
            <li>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("signup02.rea.benfits.benfit_3", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
            </li>
          </ul>
        </Panel>
      </Page>
    </>
  );
}
