export const generateLocation = (locationData) => {
  let locale = "";

  if (process.browser) {
    locale = window.localStorage.getItem("landingCityState") || "";
    if (locationData?.canonical) {
      window.localStorage.setItem("buyerAutofill", locationData?.canonical);
    }
  }

  if (locationData?.city) {
    locale = locationData.city;
  }

  if (locationData?.state) {
    locale += `, ${locationData.state}`;
  }

  if (process.browser) {
    window.localStorage.setItem("landingCityState", locale);
  }

  return locale;
};
